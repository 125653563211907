import * as React from 'react';
import { useList } from 'react-firebase-hooks/database';
import { useState } from 'react';
import { ref } from '../../utils/firebase';
import { arrayToOptions } from '../../utils/react';
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Label,
  FormGroup,
  Alert,
} from 'reactstrap';
import { PlusIcon } from '../../components/Icons';
import { logger } from '../../logging';
import { Loader } from '../../components/Loader';
import * as R from 'ramda';

const pagesStyles = require('../pages.css');
const iconStyles = require('../../components/icons.css');

const EnumerationRow = ({ refKey, val, enumeration }) => {
  const [name, setName] = useState(val.label);
  const enumRef = ref(`enumerations/${enumeration}/${refKey}`);

  const updateEnumerationLabel = async () => {
    try {
      await enumRef.update({ ...val, label: name });
    } catch (e) {
      logger.error(e);
    }
  };

  const deleteEnumeration = async () => {
    try {
      await enumRef.remove();
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <tr>
      <td>{refKey}</td>
      <td>{val.value}</td>
      <td>
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </td>
      <td>
        <Button onClick={(e) => updateEnumerationLabel()}>Update</Button>
      </td>
      <td>
        <Button onClick={(e) => deleteEnumeration()}>Delete</Button>
      </td>
    </tr>
  );
};

const CreateEnumerationRow = ({ enumeration, setValidationError }) => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);

  const reset = () => {
    setKey('');
    setValue('');
    setName('');
    setDisabled(true);
  };

  const createEnumeration = async () => {
    try {
      const enumRef = ref(`enumerations/${enumeration}/${key}`);
      // check for existing key first
      const existingKey = await enumRef.once('value');
      if (existingKey.exists()) {
        logger.info(`key already exists: enumerations/${enumeration}/${key}`);
        setValidationError(
          'Duplicate keys are not allowed, please use a different key'
        );
        reset();
        return;
      }

      // additional check for existing value for that enumeration
      const snapValueRef = ref(`enumerations/${enumeration}/`)
        .orderByChild('value')
        .equalTo(value);
      const snapValue = await snapValueRef.once('value');
      if (snapValue.numChildren() > 0) {
        logger.info(`value already exists: enumerations/${enumeration}`);
        setValidationError(
          'Duplicate values are not allowed, please use a different value'
        );
        reset();
        return;
      }

      const val = { value, label: name };
      await enumRef.update(val);
      reset();
    } catch (e) {
      logger.error(e);
      reset();
    }
  };

  const handleKeyChanged = (e) => {
    const val = e.target.value;
    const cleanVal = val.replace(/[^a-zA-Z0-9 \_]/g, '').replace(/[ ]/g, '_');
    setKey(cleanVal);
    setDisabled(true);
    setValidationError('');
    if (cleanVal !== '' && value !== '' && name !== '') {
      setDisabled(false);
    }
  };

  const handleValueChanged = (e) => {
    const val = e.target.value;
    // const cleanVal = val.replace(/[^a-zA-Z0-9 \_]/g, '').replace(/[ ]/g, '_');
    setValue(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && key !== '' && name !== '') {
      setDisabled(false);
    }
  };

  const handleNameChanged = (e) => {
    const val = e.target.value;
    setName(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && key !== '' && value !== '') {
      setDisabled(false);
    }
  };

  return (
    <tr>
      <td>
        <Input
          name="key"
          placeholder="Key"
          value={key}
          onChange={handleKeyChanged}
        />
      </td>
      <td>
        <Input
          name="value"
          placeholder="Value"
          value={value}
          onChange={handleValueChanged}
        />
      </td>
      <td>
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={handleNameChanged}
        />
      </td>
      <td>
        <Button onClick={(e) => createEnumeration()} disabled={disabled}>
          <PlusIcon fill="white" class={iconStyles.addButton} />
        </Button>
      </td>
    </tr>
  );
};

export const Enumerations = () => {
  // to populate dropdown
  const [enumeration, setEnumeration] = useState('');
  const [enumValues, enumLoading, enumError] = useList(
    ref('enumerations') as any
  );
  const [validationError, setValidationError] = useState('');

  let enumerations;
  if (enumValues && !enumLoading) {
    // we need to have the firebase key on the row so when the row is clicked we can redirect to the detail view
    const unsortedEnumerations = enumValues.map((v) => {
      return { value: v.key, label: v.key };
    });

    enumerations = unsortedEnumerations.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }

      if (a.value > b.value) {
        return 1;
      }

      return 0;
    });
  }

  // to populate table
  const [currentEnumValues, currentEnumLoading, currentEnumError] = useList(
    ref(`enumerations/${enumeration}`) as any
  );
  const [sortedCurrentEnumValues, setSortedCurrentEnumValues] =
    useState(currentEnumValues);

  if (!currentEnumValues) {
    return <Loader loading={true} />;
  }

  React.useEffect(() => {
    const values = [];
    R.map((val) => {
      values.push({ ...val.val(), key: val.key });
    }, currentEnumValues);

    const comparison = (a, b) => {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    };

    const sorted = values.sort(comparison);

    setSortedCurrentEnumValues(sorted);

    // logger.debug(
    //   `values: ${JSON.stringify(values)} typeof: ${typeof values}
    //   sorted: ${JSON.stringify(sorted)}`
    // );
  }, [currentEnumValues]);

  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Enumerations</h3>
        </Col>
      </Row>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {enumerations && (
            <FormGroup inline>
              <Input
                type="select"
                name="select"
                id="enumerationsSelect"
                onChange={(e) => setEnumeration(e.target.value)}
              >
                {arrayToOptions(enumerations)}
              </Input>
            </FormGroup>
          )}
        </Col>
      </Row>
      {enumeration && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Table striped responsive>
              <thead>
                <CreateEnumerationRow
                  enumeration={enumeration}
                  setValidationError={setValidationError}
                />
              </thead>
              <tbody>
                {sortedCurrentEnumValues.map((c) => (
                  <EnumerationRow
                    refKey={c.key}
                    val={c}
                    enumeration={enumeration}
                    key={c.key}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};
